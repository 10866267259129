<template>
    <div class="shangxin" :style="'height:'+bodyheight+'px'">
        <div class="width">
            <div class="toplogo">
                <img src="https://2lc-oss.erlangcha.com/indeximg/xlpaihang1.jpg" alt="">
            </div>
            <div class="contentList">
                <div class="dan" v-for="(item,key) in ErShopList" :key="key" v-if="item.sell">
                    <div class="nr-title">{{item.category_name}}</div>
                    <div class="kuang">
                        <div class="nr-one" v-if="item.sell">
                            <img :src="item.sell.product_img_link" v-if="item.sell.product_img_link" alt="">
                            <div class="one-title">{{item.sell.product_title}}</div>
                            <div class="one-num">总销量：{{item.sell.sell_num}}</div>
                        </div>
                        <div class="shop" v-if="item.detail.length">
                            <div class="shop-name">上新商品：</div>
                            <div class="shopList" >
                                <div class="shoplist-name"  v-for="(items,keys) in item.detail" v-if="keys < 8" :key="keys">
                                    <div class="shopkey">{{keys+1}}</div>
                                    <div class="shup-name-title">{{items.product_title}}</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-for="(item,key) in ErShopList" :key="key" class="show" :class="'pages' + onCount(key+1)">
                <img :src="item.shop_info.shop_img" alt="" class="showimg">
                <span class="showspan">{{item.shop_info.shop_title}}</span>
                <span class="showdian">点击数:{{item.aggregate}}</span>
            </div> -->
            <div class="bottom">
                <img src="https://2lc-oss.erlangcha.com/indeximg/xlpaihang2.jpg" alt="">
            </div>
            <div class="backimg">
                <div class="shuiyin">
                    <div class="dan" v-for="(item,key) in heightwin" :key="key"><img src="https://2lc-oss.erlangcha.com/indeximg/shuiyinadmin.png" alt=""></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            heightwin:'',
            pickerTime: "", //日期参数
            ErShopList: [],
            per_page: 30,
            paramData: {},
            keys: "",
            bodyheight:'',//屏幕高度
        };
    },
    watch:{
        heightwin(){
            // console.log(this.heightwin)
            // this.heightwin = document.body.clientHeight
        }
    },
    mounted() {
        if (this.$route.query.time) {
            this.pickerTime = this.$route.query.time;
        } else {
            this.pickerTime = this.getDay(-1);
        }
        this.onDataList();
        setTimeout(() => {
            this.heightwin = ((document.body.clientHeight/176).toFixed(0))*1
            this.bodyheight = this.heightwin / 2 *176/2
        }, 3000);
    },
    methods: {
        //方法
        onCount(key) {
            if (key > 4) {
                return this.onCount(key - 4);
            } else {
                return key;
            }
        },
        // 获取昨天
        getDay(day) {
            var today = new Date();
            var targetday_milliseconds =
                today.getTime() + 1000 * 60 * 60 * 24 * day;
            today.setTime(targetday_milliseconds);
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "-" + tMonth + "-" + tDate;
        },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },
        //数据列表
        onDataList() {
            this.ErShopList = [];
            var param = this.paramData;
            // param.pageSize = this.per_page;
            param.date = this.pickerTime;
            this.$service.get(this.$api.getCategoryItems, param, (res) => {
                if (res.code == "200") {
                    this.ErShopList = res.data;
                    
                    // this.lvtotal = res.data.count;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.shangxin {
    transform: scale(.5,.5);
    transform-origin: 0 0;
    .contentList{
        width: 1360px;
        padding: 0 80px;
        background: url(https://2lc-oss.erlangcha.com/indeximg/xlpaihang3.jpg) center;
        .dan{
            width: 100%;
            text-align: left;
            padding-bottom: 30px;
            
            .nr-title{
                padding: 10px 30px;
                background: #0669ba;
                font-size: 52px;
                font-weight: 900;
                border-radius: 8px;
                color: #fff;
                display: inline-block;
            }
            .kuang{
                padding: 20px;
                border-radius: 20px;
                border: 4px solid #0669ba;
                margin-top: 15px;
                .nr-one{
                    display: flex;
                    align-items: center;
                    font-size: 36px;
                    font-weight: 900;
                    color: #0669ba;
                    .one-title{
                        margin-left: 40px;
                        width: 550px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                    }
                    .one-num{
                        margin-left: auto;

                    }
                    img{
                        width: 160px;
                        height: 160px;
                    }
                }
                .shop{
                    display: flex;
                    margin-top: 20px;
                    width: 1300px;
                    .shop-name{
                        width: 190px;
                        color: #0669ba;
                        font-size: 32px;
                        font-weight: 900;
                    }
                    .shopList{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        width: 940px;
                        .shoplist-name{
                            display: flex;
                            align-items: center;
                            width: 440px;
                            border-bottom: 1px solid #0669ba;
                            margin-bottom: 20px;
                            .shopkey{
                                border-radius: 100%;
                                background: #0669ba;
                                width: 30px;
                                height: 30px;
                                flex-shrink:1;
                                text-align: center;
                                line-height: 30px;
                                color: #fff;
                                font-size: 26px;
                                font-weight: 500;
                                margin-right: 10px;
                            }
                            .shup-name-title{
                                width: 400px;
                                color: #0669ba;
                                font-size: 26px;
                                font-weight: 900;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                            }
                        }
                    }
                }
            }
        }
    }
    .width {
        width: 1360px;
        height: auto;
        margin: 0 auto;
        position: relative;
        
        .img {
            width: 100%;
        }
        .pickerTime {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100px;
            text-align: center;
            line-height: 100px;
            color: #fff;
            font-size: 60px;
            font-weight: bold;
        }
        .backimg {
            position: absolute;
            top: 0;
            left: 70px;
            display: flex;
            flex-wrap: wrap;
            text-align: left;
            width: 1550px;
            height: 600px;
            .shuiyin{
                width: 1100px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                position: absolute;
                z-index: 100;
                left: 100px;
                .dan{
                    margin: 0 150px;
                    margin-bottom: 300px;
                    opacity: .2;
                    transform:rotate(30deg);
                }
            }
            .show {
                margin: 10px 7px;
                padding: 15px;
                width: 600px;
                display: flex;
                justify-content: left;
                align-items: center;
                height: 125px;
                overflow: hidden;
                color: #fff;
                border: 4px solid #fff;
                background: url(https://2lc-oss.erlangcha.com/indeximg/xlpaihang1.jpg) center;
                background-size: 100%;
            }
            .showimg {
                width: 90px;
                height: 90px;
            }
            .showspan {
                // padding-top: 10px;
                margin-left: 20px;
                font-weight: 500;
                width: 290px;
                font-size: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
            .showdian {
                font-weight: 500;
                font-size: 22px;
                margin-left: auto;
                // padding-top: 20px;
            }
            .pages1 {
                // background: red;
                background: linear-gradient(
                    180deg,
                    #ec6819,
                    #f2901a,
                    #ec6819,
                    #f2901a
                );
            }
            .pages2 {
                background: linear-gradient(
                    180deg,
                    #199fec,
                    #1a78f3,
                    #199fec,
                    #19a2ec
                );
            }
            .pages3 {
                background: linear-gradient(
                    180deg,
                    #b71bf2,
                    #9119ee,
                    #b71bf2,
                    #9119ee
                );
            }
            .pages4 {
                background: linear-gradient(
                    180deg,
                    #ee3358,
                    #ed1a25,
                    #ee3358,
                    #ed1a25
                );
            }
            // .classTwo{
            //     background: red;
            // }
        }
    }
}
</style>
<style lang="scss">
</style>